import 'reflect-metadata';
import { Provider as ContainerProvider } from 'inversify-react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import './config/SentryConfig';
import { AuthModule } from '@vp/auth/AuthModule';
import { CommonModule } from '@vp/common/CommonModule';
import { HonorModule } from '@vp/honor/HonorModule';
import { ManagerModule } from '@vp/manager/ManagerModule';
import { AppMenuModule } from '@vp/menu/AppMenuModule';
import { AppNotificationModule } from '@vp/notification/AppNotificationModule';
import { ProfileModule } from '@vp/profile/ProfileModule';
import { RoutingModule } from '@vp/routing/RoutingModule';
import { SlideshowModule } from '@vp/slideshow/SlideshowModule';

import { App } from './App';
import { AppContainer } from './AppContainer';

const container = (): AppContainer['container'] => {
  const appContainer = new AppContainer();

  appContainer.registerModules([
    CommonModule,
    RoutingModule,
    ProfileModule,
    HonorModule,
    AuthModule,
    SlideshowModule,
    ManagerModule,
    AppMenuModule,
    AppNotificationModule,
  ]);

  appContainer.initialize();
  return appContainer.container;
};

createRoot(document.getElementById('root')!).render(
  <ContainerProvider container={container}>
    <StrictMode>
      <App />
    </StrictMode>
  </ContainerProvider>,
);
